//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n';
import HomeChart from '@/modules/home/components/home-chart.vue';
import HomeItemSummary from '@/modules/home/components/home-item-summary.vue';
import HomeProductTable from '@/modules/home/components/home-product-table.vue';
import HomeSparePartTable from '@/modules/home/components/home-spare-part-table.vue';
import HomeAccessoryTable from '@/modules/home/components/home-accessory-table.vue';
import HomeExportationItemTable from '@/modules/home/components/home-exportation-item-table.vue';

import { mapActions, mapGetters } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore'

export default {
  name: 'app-home-page',

  components: {
    [HomeChart.name]: HomeChart,
    [HomeItemSummary.name]: HomeItemSummary,
    [HomeProductTable.name]: HomeProductTable,
    [HomeSparePartTable.name]: HomeSparePartTable,
    [HomeAccessoryTable.name]: HomeAccessoryTable,
    [HomeExportationItemTable.name]: HomeExportationItemTable,
  },

  created() {
    const filter = { status: 'inStock' }
    this.doFetch({ filter });
    setTimeout(() => {
      this.notFirst = true
    }, 1000);
    this.unsubscribe = firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async snap => {
      console.log('snap meta' , snap.metadata.fromCache?'cache':'server');
      if(this.notFirst){
        this.doFetch({ filter });
      }
    //   // this.doFetchEx()
    })


    if (this.isMobile) {
      this.collapseMenu();
    }

    // const filter = { status: 'inStock' }
    //   this.doFetch({ filter });
    // firebase.firestore().collection('items').onSnapshot( async querySnapshot => {
    //   console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //     if(querySnapshot.docChanges().length){
    //       firebase.firestore().collection('items').get({source:"server"}).then(() => {
    //         console.log("enter");
    //         this.doFetch({ filter });
    //       }).catch(err => {
    //         console.log(err);
    //       })
    //     }
    // //   // console.log(`Received query Docs of size ${querySnapshot.docs.length}`);
    // //   // console.log(`Received query Changes of size ${querySnapshot.docChanges().length}`);
    // //   // console.log((await firebase.firestore().collection('items').get({source: "cache"})).docs.length);
    // }, err => {
    //    console.log(`Encountered error: ${err}`);
    // });
  },

  data() {
    return {
      filterMonth: 0,
      notFirst:false,
      unsubscribe:undefined
      
    }
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
    }),
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.home') 
    },
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetch: 'importer/list/doFetch',
      // doFetchEx: 'exporter/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    setFilterMonth(month){
      this.filterMonth = month
    }
  }
};
