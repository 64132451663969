//
//
//
//
//
//
//
//
//

// import uuid from 'uuid/v4';
// import Chart from 'chart.js';

export default {
  name: 'app-home-chart',

  props: ['config'],

  data() {
    return {
      // id: uuid(),
    };
  },

  mounted() {
    // const ctx = document.getElementById(this.id);
    // new Chart(ctx, this.config);
  },
};
