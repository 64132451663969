//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'app-counter-view',
  props: ['label', 'value', 'label-color', 'val-color', 'background', 'loading'],

  data() {
    return {
    
    };
  },
  computed: {
    ID() {
      return `value-${this.label}`
    },
    displayValue() {
      // const confirmed = document.getElementById("confirmed");
      // const recovered = document.getElementById("recovered");
      // const deaths = document.getElementById("deaths");
      // if(navigator.onLine) {
      //   this.animateValue(confirmed, 0, this.globalCached.totalConfirmed, 5000);
      //   this.animateValue(recovered, 0, this.globalCached.totalRecovered, 5000);
      //   this.animateValue(deaths, 0, this.globalCached.totalDeaths, 5000);
      // },
      let value = this.value
      if (navigator.onLine) {
        value = this.animateValue(0, this.value, 5000);
      }
      return value
    }
  },
  // mounted() {
  //  
  //   const value = document.getElementById("value");
  //   if(navigator.onLine) {
  //     this.animateValue(value, 0, this.value, 5000);
  //   }
  // },
  methods: {
    animateValue(obj, start, end, duration) {
      if (obj) {
        let startTimestamp = null;
        const step = (timestamp) => {
          if (!startTimestamp) startTimestamp = timestamp;
          const progress = Math.min((timestamp - startTimestamp) / duration, 1);
          obj.innerHTML = this.numberWithCommas(Math.floor(progress * (end - start) + start));
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
        };
        window.requestAnimationFrame(step);
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  // watch: {
  //   value() {
  //     let id = `value-${this.label}`
  //     const valueObj = document.getElementById(id);
  //     if(navigator.onLine) {
  //       this.animateValue(valueObj, 0, this.value, 5000);
  //     }
  //   }
  // },
};
