//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { AccessoryModel } from '@/modules/accessory/accessory-model';
import { AccessoryPermissions } from '@/modules/accessory/accessory-permissions';
// import { i18n } from '@/vueI18n';
// import moment from 'moment';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

const { fields } = AccessoryModel;

export default {
  name: 'app-home-accessory-table',
  props:['filterMonth'],
  // props: ['rows', 'count'],

  // components:{
  //   [accessorysViewPage.name]: accessorysViewPage,
  // },

  data() {
    return {
      // language: getLanguageCode(),
      text: '',
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      Pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: 'id',
          field: 'id',
          label:'entities.accessory.fields.id',
          align: 'left',
          required: true,
          // sortable: true,
        },
        {
          name: 'name',
          field: 'name',
          label: 'entities.accessory.fields.name',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'right',
        },
      ],
    };
  },

  mounted() {
    this.doFilter();
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'accessory/list/rows',
      count: 'accessory/list/count',
      loading: 'accessory/list/loading',
      destroyLoading: 'accessory/destroy/loading',
      pagination: 'accessory/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
      saveLoading: 'accessory/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs'
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new AccessoryPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new AccessoryPermissions(this.currentUser).destroy;
    },
    hasPermissionToCreate() {
      return new AccessoryPermissions(this.currentUser).create;
    },

    lastPage() {
      return Math.ceil(this.rows.length / this.pageSize)
    },
      // lastPage: Math.ceil(this.rows.length / this.pageSize),
  },

  methods: {
    ...mapActions({
      doFetch: 'accessory/list/doFetch',
      doChangeSort: 'accessory/list/doChangeSort',
      doChangePaginationCurrentPage: 'accessory/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'accessory/list/doChangePaginationPageSize',
      doMountTable: 'accessory/list/doMountTable',
      doDestroy: 'accessory/destroy/doDestroy',
      doUpdate: 'accessory/form/doUpdate',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return AccessoryModel.presenter(row, fieldName);
    },

    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },

    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'view':
          this.dialogType = 'view'
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm(id) {
      try {
        // await this.$confirm(
        //   i18n('common.areYouSure'),
        //   i18n('common.confirm'),
        //   {
        //     confirmButtonText: i18n('common.yes'),
        //     cancelButtonText: i18n('common.no'),
        //     type: 'warning',
        //   },
        // );

        await this.doDestroy(id);
        this.closeDialog()
      } catch (error) {
        // no
      }
    },
    
    async doFilter() {
      let filter = {}
      if(this.filterMonth > 0){
        filter['createdAtRange'] = [new Date(`${this.filterMonth}-01-${new Date().getFullYear()}`),new Date(`${this.filterMonth + 1}-01-${new Date().getFullYear()}`)]
      }
      // let isOr = false
      // await this.doChangePaginationPageSize(5)
      return this.doFetch({ filter });
      // this.$emit('filter')
    },

    // async doSubmit(payload) {
    //   await this.doUpdate(payload);
    //   this.closeDialog();
    //   this.doFilter();
    // },

    async onScroll ({ to, ref }) {
     
      const lastIndex = this.rows.length - 1

      if (this.loading !== true && this.nextPage < this.lastPage && to === lastIndex) {
      await this.doChangePaginationPageSize(5)
          this.nextPage++

        this.$nextTick(() => {
            ref.refresh()
            this.loading = false
          })

        // this.loading = true

        // setTimeout(() => {
        //   this.nextPage++
        //   this.$nextTick(() => {
        //     ref.refresh()
        //     this.loading = false
        //   })
        // }, 500)


      }
    },
  },

  watch:{
    filterMonth(){
      this.doFilter()
    }
  }
};
