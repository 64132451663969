import { render, staticRenderFns } from "./home-spare-part-table.vue?vue&type=template&id=7915664e&scoped=true&"
import script from "./home-spare-part-table.vue?vue&type=script&lang=js&"
export * from "./home-spare-part-table.vue?vue&type=script&lang=js&"
import style0 from "./home-spare-part-table.vue?vue&type=style&index=0&id=7915664e&lang=scss&scoped=true&"
import style1 from "./home-spare-part-table.vue?vue&type=style&index=1&lang=sass&"
import style2 from "./home-spare-part-table.vue?vue&type=style&index=2&id=7915664e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7915664e",
  null
  
)

export default component.exports
import {QIcon,QTooltip,QTable,QTr,QTd,QBtn,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon,QTooltip,QTable,QTr,QTd,QBtn,QDialog})
