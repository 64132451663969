//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CounterView from '@/modules/home/components/counter-view.vue';
import { mapGetters } from 'vuex';
import  i18n  from '@/vueI18n';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
export default {
  name: 'app-home-item-summary',
  
  components: {
    [CounterView.name]: CounterView,
  },  
    
  created(){
    // this.factoryLoading = true
    // this.unsubscribe = firebase.firestore().collection('items').where('status','==','inFactory').onSnapshot(snap => {
    //   this.factoryLoading = true
    //   this.factoryCount = snap.docs.length
    //   this.factoryLoading = false
    // })
    this.productLoading = true
    firebase.database().ref('productCount').on('value', (snapshot) => {
      this.productLoading = true
      console.log(snapshot.val());
      this.productCount = snapshot.val();
      this.productLoading = false
    });

    this.sparePartLoading = true
    firebase.database().ref('sparePartCount').on('value', (snapshot) => {
      this.sparePartLoading = true
      console.log(snapshot.val());
      this.sparePartCount = snapshot.val();
      this.sparePartLoading = false
    });

    this.accessoryLoading = true
    firebase.database().ref('accessoryCount').on('value', (snapshot) => {
      this.accessoryLoading = true
      console.log(snapshot.val());
      this.accessoryCount = snapshot.val();
      this.accessoryLoading = false
    });

    this.importerLoading = true
    firebase.database().ref('inStockItems').on('value', (snapshot) => {
      this.importerLoading = true
      console.log(snapshot.val());
      this.importerCount = snapshot.val();
      this.importerLoading = false
    });

    this.factoryLoading = true
    firebase.database().ref('inFactroyItems').on('value', (snapshot) => {
      this.factoryLoading = true
      console.log(snapshot.val());
      this.factoryCount = snapshot.val();
      this.factoryLoading = false
    });

    this.exporterLoading = true
    firebase.database().ref('exportedItems').on('value', (snapshot) => {
      this.exporterLoading = true
      console.log(snapshot.val());
      this.exporterCount = snapshot.val();
      this.exporterLoading = false
    });
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  data() {
    return {
      importerCount: 0,
      importerLoading: false,
      exporterCount: 0,
      exporterLoading: false,
      factoryCount: 0,
      factoryLoading: false,
      productCount: 0,
      productLoading: false,
      sparePartCount: 0,
      sparePartLoading: false,
      accessoryCount: 0,
      accessoryLoading: false,
      unsubscribe: undefined,
      selectedMonth: {
        label:'months.allTime',
        value: 0
      },
      months: [
      {
        label:'months.allTime',
        value: 0
      },
      {
        label:'months.January',
        value:1
      }, 
      {
        label:'months.February',
        value:2
      }, 
      {
        label:'months.March',
        value:3
      }, 
      {
        label:'months.April',
        value:4
      }, 
      {
        label:'months.May',
        value:5
      }, 
      {
        label:'months.June',
        value:6
      }, 
      {
        label:'months.July',
        value:7
      }, 
      {
        label:'months.August',
        value:8
      }, 
      {
        label:'months.September',
        value:9
      }, 
      {
        label:'months.October',
        value:10
      }, 
      {
        label:'months.November',
        value:11
      }, 
      {
        label:'months.December',
        value:12
      }]
    };
  },
  computed: {
    ...mapGetters({
      // productCount: 'product/list/count',
      // productLoading: 'product/list/loading',
      // sparePartCount: 'sparePart/list/count',
      // sparePartLoading: 'sparePart/list/loading',
      // accessoryCount: 'accessory/list/count',
      // accessoryLoading: 'accessory/list/loading',

      // importerCount: 'importer/list/count',
      // importerLoading: 'importer/list/loading',

      // exporterCount: 'exporter/list/count',
      // exporterLoading: 'exporter/list/loading',
    }),
    totalCount() {
      // return this.productCount + this.sparePartCount + this.accessoryCount + this.importerCount + this.exporterCount
      return this.importerCount + this.exporterCount
    },
    totalLoading() {
      // return this.productLoading || this.sparePartLoading || this.accessoryLoading || this.importerLoading || this.exporterLoading
      return this.importerLoading || this.exporterLoading
    },
    language(){
      return i18n.locale;
    }
  },
  methods: {
    onItemClick (m) {
       console.log('Clicked on an Item',m.value)
      this.selectedMonth = m
      this.$emit('setFilterMonth',m.value)
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  }
};
